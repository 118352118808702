<template>
  <div id="EditorDocumento">

    <div class="form">
        <div id="widget"></div>
        <div id="iframeEditor">
      </div>
    </div>

  </div>
  </template>

<script>

  // $(function() {
  //   $('.link').on("click", function(e) {
  //     e.preventDefault();
  //     var href = $(this).attr('href');
  //     console.log('href:::', href)
  //     $("#content").html('<object data="' + href + '" />');
  //   });
  // });

  import { API } from "../../../services/API"
  import axios from "axios";
  import { COR_PRINCIPAL, COR_SECUNDARIA, COR_SUBTITULO } from "../../../services/constantes";
  import store_usuario from "../../../store/store_usuario"
  import store_site from "../../../store/store_site";
  import { formatDateTime, formatDate } from "../../../services/funcoes";

  export default {
    name: 'EditorDocumento',

    props : {
      ACAO : {
        type  : String  // NOVO_MODELO_LIMPO, NOVO_MODELO_UPLOAD, outros
      },
      ARQUIVO : {       // Nome do Arquivo docx (geralmente filename do banco de dados)
        type : String
      },
      USER_ADDRESS : {  // Nome da empresa no formato: "empresa_000001" seis digitos no numero
        type : String
      },
      TIPO : {          // TIPO = 'proposta', 'contrato', 'transferencia', 'quitacao', 'autorizacao' 'rescisao'
        type : String
      },
      CAMINHO : {       // Subdiretório do arquivo Ex: "modelo_empreend", 'documentos_venda'
        type : String
      },
      URL_API : {       // url da API de acesso ao servidor do BackEnd para o Callback de Retorno (webhook)
        type : String
      },
      TITULO : {        // Titulo que irá aparecer no editor de documento (nome do arquivo)
        type : String
      },
      TIPO_VISUALIZACAO : {  // tipo de visualização do editor na tela
        type : String  // "desktop", "view", "embbeded"
      }
    },

    components: {
    },

    data() {
      return {
        store_site            : store_site,
        store_usuario         : store_usuario,

        COR_PRINCIPAL         : COR_PRINCIPAL,
        COR_SUBTITULO         : COR_SUBTITULO,
        COR_SECUNDARIA        : COR_SECUNDARIA,

        formatDateTime  : formatDateTime,
        formatDate      : formatDate,

        expand          : true,
        tab             : null,

        docEditor       : null,
        docConnector    : null,
        config          : null,
        payload         : null,

        header          : {},
        secret          : "FRH3dSQ7CuWVzYdZIaZ1AyXE3VIrI3AQ",
        //URL_SERVER      : "https://a8e6-186-249-32-96.ngrok-free.app", // ngrok http 3998 --host-header=http://localhost:8080
        URL_SERVER      : "https://editor-backend.younect.com.br",
        //URL_SERVER_2      : "http://localhost:3998",

        date          : null,
        DATE_IAT      : null,
        DATE_EXP      : null,
        CALLBACK      : null,
        LANG          : "pt",
        KEY           : "",

        stringifiedHeader : null,
        encodedHeader     : null,
        stringifiedData   : null,
        encodedData       : null,
        token             : null,
        signature         : null,
        signedToken       : null,
      }

    },

    async created() {

    },

    async mounted() {
        // console.log('MOUNTED!!!!!!!!!!!!!!!!!!!!!!') 
        // console.log( '1', this.ACAO)
        // console.log( '2', this.ARQUIVO)
        // console.log( '3', this.USER_ADDRESS)
        // console.log( '4', this.TIPO)
        // console.log( '5', this.CAMINHO)
        // console.log( '6', this.URL_API)
        // console.log( '7', this.TITULO)
        // console.log( '8', this.TIPO_VISUALIZACAO);
        // console.log( '9', this.URL_SERVER);

      // ACAO: "ABRIR_MODELO"
      // ARQUIVO: "empreend_modelo_rescisao_000001_000031_000140.docx"
      // CAMINHO: "empreend_modelo/rescisao"
      // TIPO: "rescisao"
      // TITULO: "Rescisão 1"
      // URL_API: "http://localhost:30001/"
      // USER_ADDRESS: "empresa_000001"      

      //   console.log('file',`${this.URL_SERVER}/download?fileName=${this.ARQUIVO}&useraddress=${this.USER_ADDRESS}&caminho=${this.CAMINHO}`);

      Date.prototype.addDays = function(days) {
        var date = new Date(this.valueOf());
        date.setDate(date.getDate() + days);
        return date;
      }

      if (this.ACAO == 'NOVO_MODELO_LIMPO') {
        this.KEY            = this.ARQUIVO;
      }
      else {
        let ls_Key       = await this.getFileId(this.ARQUIVO);
        this.KEY         = `${ls_Key}`;
        // console.log('KEY !!!!!!!!!!!!', this.KEY)
      }

      this.date           = new Date();
      this.DATE_IAT       = this.jsDateToEpoch(this.date);
      this.DATE_EXP       = this.jsDateToEpoch(this.date.addDays(2));
      const younect_token = localStorage.getItem("@YouNect-Imob-" + window.location.hostname + ":token");
      this.CALLBACK       = `${this.URL_SERVER}/track?filename=${this.ARQUIVO}&useraddress=${this.USER_ADDRESS}&url_api=${this.URL_API}&token_younect=${younect_token}&caminho=${this.CAMINHO}`;
      console.log('this.CALLBACK::', this.CALLBACK);

      this.header = {
                      "alg": "HS256",
                      "typ": "JWT"
                    };

      this.payload = {
            "document": {
              "directUrl": "",
              "fileType": "docx",
              "info": {
                "owner": "",
                "uploaded": "Sat Apr 01 2023",
                "favorite": null
              },
              //"key": `${this.USER_ADDRESS}new.docx${this.KEY}`,
              "key": `${this.KEY}`,
              "permissions": {
                "chat": false,
                "comment": false,
                "copy": true,
                "download": true,
                "edit": true,
                "fillForms": false,
                "modifyContentControl": false,
                "modifyFilter": false,
                "print": true,
                "review": true,
                "reviewGroups": null,
                "commentGroups": {},
                "userInfoGroups": null,
                "changeHistory"	: true,
                "rename" : false
              },
              "referenceData": {
                "fileKey": `{\"fileName\":\"${this.ARQUIVO}\",\"userAddress\":\"${this.USER_ADDRESS}\"}`,
                "instanceId": `${this.URL_SERVER}`
              },
              "title": `${this.TITULO}`,
              "url": `${this.URL_SERVER}/download?fileName=${this.ARQUIVO}&useraddress=${this.USER_ADDRESS}&caminho=${this.CAMINHO}`
            },
            "documentType": "word",
            "editorConfig": {
                "actionLink": null,
                "callbackUrl": this.CALLBACK,
                "coEditing": null,
                "createUrl": `${this.URL_SERVER}/editor?fileExt=docx&userid=uid-1&type=${this.TIPO_VISUALIZACAO}&lang=${this.LANG}`,
                "customization": {
                  "logo" : {
                    "image" : "http://www.viscom.com.br/img/logo-header.png",
                    "imageDark" : "http://www.viscom.com.br/img/logo-header.png",
                    "imageEmbedded" : "http://www.viscom.com.br/img/logo-header.png",
                    "url" : "http://www.viscom.com.br"
                  },
                  "about": false,
                  "comments": false,
                  "feedback": false,
                  "forcesave": false,
                  "toolbarHideFileName" : true,
                  "compactHeader": true,
                  "logo" : {
                    "image" : "http://www.viscom.com.br/img/logo-header.png",
                    "imageDark" : "http://www.viscom.com.br/img/logo-header.png",
                    "imageEmbedded" : "http://www.viscom.com.br/img/logo-header.png",
                    "url" : "http://www.viscom.com.br"
                  },
                  "goback": {
                    //"url": `/empreendimentomodelodocumento/${this.$route.params.cod_empreendimento}`
                    "url": null
                  },
                  "submitForm": false,
                  "review" : {
                    "hideReviewDisplay" : true,
                    "hoverMode" : true,
                    "showReviewChanges" : false,
                    "trackChanges" : false
                  },
                  "unit" : "cm"
                },
              "integrationMode": "embed",
              "embedded": {
                "embedUrl": `${this.URL_SERVER}/embedded?fileName=${this.ARQUIVO}`,
                "fullscreenUrl": `${this.URL_SERVER}/embedded?doc=${this.ARQUIVO}#fullscreen`,
                "saveUrl": `${this.URL_SERVER}/download?fileName=${this.ARQUIVO}`,
                "shareUrl": `${this.URL_SERVER}/download?fileName=${this.ARQUIVO}`,
                "toolbarDocked": "top"
              },
              "fileChoiceUrl": "",
              "lang": `${this.LANG}`,
              "mode": "embedded",
              "plugins": {
                "pluginsData": []
              },
              "templates": [
                {
                    "image": "",
                    "title": "Blank",
                    "url": `${this.URL_SERVER}/editor?fileExt=docx&userid=uid-1&type=${this.TIPO_VISUALIZACAO}&lang=${this.LANG}`
                },
                {
                    "image": `${this.URL_SERVER}/images/file_docx.svg`,
                    "title": "With sample content",
                    "url": `${this.URL_SERVER}/editor?fileExt=docx&userid=uid-1&type=${this.TIPO_VISUALIZACAO}&lang=${this.LANG}&sample=true`
                }
              ],
              "user": {
                "group": "",
                "id": `uid-${store_usuario.user.cod_usuario}`,
                "name": `${store_usuario.user.pessoa_nome}`
              }
            },
            "height": "100%",
            "token": "",
            "type": `${this.TIPO_VISUALIZACAO}`,
            "width": "100%",
            "iat": this.DATE_IAT,
            "exp": this.DATE_EXP,
            "parentOrigin" : "https://demo.younect.com.br"
        }

      this.signedToken = this.cria_token(this.header, this.payload, this.secret);

      this.config = {
        ...this.payload,
        "token": this.signedToken,
        }

      // console.log(this.config);

      this.config.events = {
          "onAppReady": this.onAppReady,
          "onDownloadAs": this.onDownloadAs,
          "onDocumentStateChange": this.onDocumentStateChange,
          "onRequestEditRights": this.onRequestEditRights,
          "onError": this.onError,
          "onOutdatedVersion": this.onOutdatedVersion,
          "onMakeActionLink": this.onMakeActionLink,
          "onMetaChange": this.onMetaChange,
          "onShowMessage": this.onShowMessage,
          "onRequestInsertImage": this.onRequestInsertImage,
          "onRequestCompareFile": this.onRequestCompareFile,
          "onRequestMailMergeRecipients": this.onRequestMailMergeRecipients,
      };

console.log('CONFIG::', this.config);

      if ("uid-1" != null) {
        this.config.events.onRequestHistory = this.onRequestHistory;
        this.config.events.onRequestHistoryData = this.onRequestHistoryData;
        this.config.events.onRequestHistoryClose = this.onRequestHistoryClose;
        //this.config.events.onRequestRename = this.onRequestRename;
        this.config.events.onRequestUsers = this.onRequestUsers;
        this.config.events.onRequestSendNotify = this.onRequestSendNotify;
        this.config.events.onRequestReferenceData = this.onRequestReferenceData;
      }

      if (this.config.editorConfig.createUrl) {
        this.config.events.onRequestSaveAs = this.onRequestSaveAs;
      }

      if (new URL(window.location).searchParams.get("oform") == "false") {
        this.config.document.options = this.config.document.options || {};
        this.config.document.options["oform"] = false;
      }

      //if (window.addEventListener) {
      this.connectEditor();
      window.addEventListener("resize", this.fixSize);
      //} else
      if (window.attachEvent) {
          window.attachEvent("onload", this.connectEditor);
          window.attachEvent("onresize", this.fixSize);
      }
    },

    methods: {

      cria_token(header, payload, secret) {
        let stringifiedHeader = CryptoJS.enc.Utf8.parse(JSON.stringify(header));
        let encodedHeader = this.base64url(stringifiedHeader);
        let stringifiedData = CryptoJS.enc.Utf8.parse(JSON.stringify(payload));
        let encodedData = this.base64url(stringifiedData);
        let token = encodedHeader + "." + encodedData;
        let signature = CryptoJS.HmacSHA256(token, secret);
            signature = this.base64url(signature);
        return token + "." + signature;
      },

      // Criar novo documento =================================================================
      async cria_documento(filename) {
        const younect_token = localStorage.getItem("@YouNect-Imob-" + window.location.hostname + ":token");
        const API = axios.create({
                                  baseURL: `${this.URL_SERVER}`,
                                  headers: {"x_user_address": `${this.USER_ADDRESS}`,
                                            "token_younect" : younect_token,
                                            "x_caminho"     : this.CAMINHO,
                                            "ngrok-skip-browser-warning":"any"
                                          },
                                  responseType: "json",
                                });
        const body = {
          "url_api"     : this.URL_API,
          "caminho"     : this.CAMINHO,
          "filename"    : this.ARQUIVO,
          "title"       : this.ARQUIVO,
          "url_modelo"  : filename
        }
        const ls_Resp = await API.post(`/create`,
                                        body
                                      );
      },

      // Criar novo documento =================================================================
      // async cria_documento_upload(fileContext) {
      //   const younect_token = localStorage.getItem("@YouNect-Imob-" + window.location.hostname + ":token");
      //   const API = axios.create({
      //                             baseURL: `${this.URL_SERVER}`,
      //                             headers: {"x_user_address": `${this.USER_ADDRESS}`,
      //                                       "token_younect" : younect_token,
      //                                       "x_caminho"     : this.CAMINHO,
      //                                       "ngrok-skip-browser-warning":"any"
      //                                     },
      //                             responseType: "json",
      //                           });
      //   const body = {
      //     "url_api"     : this.URL_API,
      //     "caminho"     : this.CAMINHO,
      //     "filename"    : this.ARQUIVO,
      //     "title"       : this.ARQUIVO,
      //     "url_modelo"  : filename
      //   }
      //   const signedToken = this.cria_token(this.header, body, this.secret);
      //   // console.log('this.signedToken:::::::::::::::: ', signedToken);
      //   const ls_Resp = await API.post(`/create`,
      //                                   body
      //                                 );
      //   // console.log("DOCUMENTO :::::", ls_Resp);
      // },

      // Criar Miniatura =================================================================
      async cria_png(url) {
        let ls_DATE_IAT  = this.jsDateToEpoch(new Date());
        const API3 = axios.create({
                                    baseURL: `${this.URL_SERVER}`,
                                    headers: {"ngrok-skip-browser-warning":"any"},
                                    responseType: "json",
                                  });
        const _body3 = {
            "filetype": "docx",
            "key": `thumb_${ls_DATE_IAT}`,
            "outputtype": "png",
            "thumbnail": {
              "aspect": 1,
              "width" : 450,
              "height" : 450,
              "first": true
            },
            "title": `thumb_${ls_DATE_IAT}`,
            "url": url
        }
        const signedToken = this.cria_token(this.header, _body3, this.secret);
        const ls_Resp3 = await API3.post(`/ConvertService`,
                                          {"token": signedToken}
                                        );
        // Gravando PNG no banco
        if (ls_Resp3.data.fileUrl) {
          let lo_Body =   { 
                        "ultima_alteracao_dt"   : new Date(),
                        "downloadUri"           : null,
                        "thumbnailUri"          : ls_Resp3.data.fileUrl,
                        "caminho"               : this.CAMINHO,
                        "filename"              : this.ARQUIVO,
                        }
        const ls_Resp = await API.post(`/callback_onlyoffice_para_backend/${this.ARQUIVO}`,
                                        lo_Body
                                      );
        }

      },

      // Criar PDF =================================================================
      async cria_pdf(url) {
        let ls_DATE_IAT  = this.jsDateToEpoch(new Date());
        const API3 = axios.create({
                                    baseURL: `${this.URL_SERVER}`,
                                    headers: {"ngrok-skip-browser-warning":"any"},
                                    responseType: "json",
                                  });
        const _body3 = {
            "filetype": "docx",
            "key": `thumb_${ls_DATE_IAT}`,
            "outputtype": "pdf",
            "title": `thumb_${ls_DATE_IAT}`,
            "url": url
        }
        const signedToken = this.cria_token(this.header, _body3, this.secret);
        const ls_Resp3 = await API3.post(`/ConvertService`,
                                          {"token": signedToken}
                                        );
        //console.log("PDF CONVERTIDO :::::", ls_Resp3);
      },

      // getFileId, pega Key do arquivo existente
      async getFileId(filename) {
        //console.log("ENTROU getFileId ::::: ", this.URL_SERVER, filename);
        const API = axios.create({
                                  baseURL: `${this.URL_SERVER}`,
                                  headers: {"x_user_address": `${this.USER_ADDRESS}`,
                                            "x_caminho": `${this.CAMINHO}`,
                                            "ngrok-skip-browser-warning":"any"
                                           },
                                  responseType: "json",
                                });
        const ls_Resp = await API.get(`/getfileid`, {params : {fileName : filename}});
        //console.log("resposta getFileId :::::", ls_Resp);
        if (ls_Resp.data && ls_Resp.data.fileid)
          return ls_Resp.data.fileid
        else
          return null;
      },

      // streamToBase64(stream) {
      //   const concat = require('concat-stream')
      //   const { Base64Encode } = require('base64-stream')

      //   return new Promise((resolve, reject) => {
      //     const base64 = new Base64Encode()
      //     const cbConcat = (base64) => {
      //       resolve(base64)
      //     }
      //     stream
      //       .pipe(base64)
      //       .pipe(concat(cbConcat))
      //       .on('error', (error) => {
      //         reject(error)
      //       })
      //   })
      // },

      jsDateToEpoch(d){
        return (d.getTime()-d.getMilliseconds())/1000;
      },

      base64url(source) {
        // Encode in classical base64
        let encodedSource = CryptoJS.enc.Base64.stringify(source);
        // Remove padding equal characters
        encodedSource = encodedSource.replace(/=+$/, '');
        // Replace characters according to base64url specifications
        encodedSource = encodedSource.replace(/\+/g, '-');
        encodedSource = encodedSource.replace(/\//g, '_');
        return encodedSource;
      },

      innerAlert : function (message, inEditor) {
          if (console && console.log)
              console.log(message);
          if (inEditor && docEditor)
              docEditor.showMessage(message);
      },

      onAppReady : function () {  // the application is loaded into the browser
        // this.innerAlert("Document editor ready ! ");
      },

      onReady : function () {  // the application is loaded into the browser
        this.innerAlert("Document rrrrrrrrrrrrrrrrrready ! ");
      },

      onDownloadAs : async function(resp) {
        console.log('onDownloadAs =====================', resp);

        // Criando o Thumb png
        //this.cria_png(resp.data.url);
        this.cria_pdf(resp.data.url);

        //this.docEditor.destroyEditor();
      },

      onDocumentStateChange : async function (event) {  // the document is modified
        var title = document.title.replace(/\*$/g, "");
        document.title = title + (event.data ? "*" : "");

        // Tentando pegar as alterações
        if (!event.data) {

          // console.log('onDocumentStateChange 1 +++++ ')
          // this.docEditor.downloadAs("docx"); // ++++++++++++++++++ DEU CERTO +++++++++++++++++++
          // console.log('onDocumentStateChange 2 +++++ ');

// Track Force Save ===========================================================
//           const _body = {
//                           "key": `${this.KEY}`,
//                           "status": 6,
//                           //"url": 'http://86.48.28.195/cache/files/data/Khirz6zTPdfd_4844/output.docx/output.docx?md5=glHJRknKtg6oVfS5qq-OtQ&expires=1680663735&filename=output.docx',
//                           "url": `${this.URL_SERVER}/download?fileName=new.docx&useraddress=${this.USER_ADDRESS}`,
//                           //"changesurl": 'http://86.48.28.195/cache/files/data/Khirz6zTPdfd_4844/changes.zip/changes.zip?md5=wZZLCqjex0YW_8N506sPcA&expires=1680663735&filename=changes.zip',
//                           //"history": { serverVersion: '7.3.3', changes: [ [Object] ] },
//                           "users": [ 'uid-1' ],
//                           "actions": [ { type: 2, userid: 'uid-1' } ],
//                           //"lastsave": '2023-04-05T02:45:36.000Z',
//                           "forcesavetype": 1,
//                           "token": this.signedToken,
//                           "filetype": 'docx'
//                         }

//                         const API = axios.create({
//                           baseURL: `${this.URL_SERVER}`,
//                           headers: {'Authorization': `Bearer ${this.signedToken}`},
//                           responseType: "json",
//                         });
//                         const ls_Resp = await API.post(`/track?filename=new.docx&useraddress=${this.USER_ADDRESS}`,
//                                                         _body
//                                                        );
// console.log("ls_Resp.data:::::", ls_Resp);
// =========================================================================

// Download ======================================================================================================
                        // const API = axios.create({
                        //   baseURL: `${this.URL_SERVER}`,
                        //   headers: {'Authorization': `Bearer ${this.signedToken}`},
                        //   responseType: "json",
                        // });
                        // const ls_Resp = await API.get(`/download`, {
                        //                                             params: {
                        //                                               fileName      : "new.docx",
                        //                                               fileType      : "docx",
                        //                                               documentType  : "pdf",
                        //                                               useraddress   : `${this.USER_ADDRESS}`,
                        //                                               token         : `${this.signedToken}`
                        //                                             }
                        // });
                        // const { Readable } = require('stream');
                        // const stream = Readable.from(ls_Resp.data);
                        // console.log("ls_Resp.data:::::", stream);
// =================================================================================================================

// Download ======================================================================================================
      // const API = axios.create({
      //   baseURL: `${this.URL_SERVER}`,
      //   headers: {'Authorization': `Bearer ${this.signedToken}`},
      //   responseType: "json",
      // });
      // const ls_Resp = await API.get(`/download2`, {
      //                                             params: {
      //                                               fileName      : "new.docx",
      //                                               fileType      : "docx",
      //                                               documentType  : "pdf",
      //                                               useraddress   : `${this.USER_ADDRESS}`,
      //                                               token         : `${this.signedToken}`
      //                                             }
      // });
      // console.log("ls_Resp.data:::::", ls_Resp);
//===============================================================================

                        // const API2 = axios.create({
                        //   baseURL: `http://86.48.28.195`,
                        //   responseType: "json",
                        // });
                        // const ls_Resp = await API2.post(`/coauthoring/CommandService.ashx`,
                        //                                 {
                        //                                   "c": "forcesave",
                        //                                   "key": `${this.KEY}`,
                        //                                   "userdata": "sample userdata"
                        //                                 }
                        //                                );

                        // const ls_Resp = await API.get(`/force?filename=new.docx&useraddress=${this.USER_ADDRESS}&key=${this.KEY}&token=${this.signedToken}`);

                        // const ls_Base64 = btoa(unescape(encodeURIComponent(ls_Resp.data)));
                        // console.log("ls_Resp.data:::::", ls_Resp.data);

// Convert =================================================================
// const API3 = axios.create({
//   baseURL: `${this.URL_SERVER}`,
//   headers: {'Authorization': `Bearer ${this.signedToken}`},
//   responseType: "json",
// });
// // const _body3 = {
// //     "async": false,
// //     "filetype": "docx",
// //     "key": `${this.KEY}`,
// //     "outputtype": "pdf",
// //     "token" : `${this.signedToken}`,
// //     "title": "Example Document Title.docx",
// //     "url": `${this.URL_SERVER}/download?fileName=new.docx&useraddress=${this.USER_ADDRESS}`
// // }
// console.log("ls_Resp.data Convert 1 :::::");
// const _body3 = {
//     "filetype": "docx",
//     "key": `${this.KEY}`,
//     "outputtype": "png",
//     "token" : `${this.signedToken}`,
//     "thumbnail": {
//         "aspect": 0,
//         "first": true,
//         "height": 150,
//         "width": 100
//     },
//     "title": "Example Document Title.docx",
//     "url": `${this.URL_SERVER}/download?fileName=new.docx&useraddress=${this.USER_ADDRESS}`
// }
// console.log("ls_Resp.data Convert 2 :::::");
// const ls_Resp3 = await API3.post(`/ConvertService`,
//                                 _body3
//                                 );
// console.log("ls_Resp.data Convert 3 :::::", ls_Resp3);
// ============================================================

// File  =================================================
// const API4 = axios.create({
//                           baseURL: `${this.URL_SERVER}`,
//                           headers: {'Authorization': `Bearer ${this.signedToken}`},
//                           responseType: "json",
//                         });
// const ls_Resp4 = await API4.get(`/files`);
// console.log("ls_Resp.data 4 :::::", ls_Resp4);
// ==================================================================================================

// Files =================================================
// const API5 = axios.create({
//                           baseURL: `${this.URL_SERVER}`,
//                           headers: {'Authorization': `Bearer ${this.signedToken}`},
//                           responseType: "json",
//                         });
// const ls_Resp5 = await API5.get(`/files/file/${this.KEY}/presigneduri`);
// console.log("ls_Resp.data 5 :::::", ls_Resp5);
// ==================================================================================================

// Convert ======================================================
// const API6 = axios.create({
//                           baseURL: `${this.URL_SERVER}`,
//                           headers: {'Authorization': `Bearer ${this.signedToken}`},
//                           responseType: "json",
//                         });
// const _body6 = {
//   filename : "new.docx",
//   filePass : null,
//   lang : null,
//   async: false,
//   filetype: "docx",
//   key: `${this.KEY}`,
//   outputtype: "pdf",
//   //   "title": "Example Document Title.docx"
//   //   //"url": `${this.URL_SERVER}/download?fileName=new.docx`
// }
// const ls_Resp6 = await API6.post(`/convert`,
//                                 _body6
//                                 );
// console.log("ls_Resp.data 6 :::::", ls_Resp6);
// ==========================================

// getFileId ================================
// const API7 = axios.create({
//                           baseURL: `${this.URL_SERVER}`,
//                           headers: {'Authorization': `Bearer ${this.signedToken}`},
//                           responseType: "json",
//                         });
// const ls_Resp7 = await API7.get(`/getFileId?fileName=new.docx&token=${this.signedToken}`);
// console.log("ls_Resp.data 7 :::::", ls_Resp7);
// ==========================================


          //var url = event.data.url;
//          var url = this.config.document.url;
//          var data = {
//              title: title,
//              url: url
//          }
//          var file = syncRequest("GET", `${this.URL_SERVER}/track?filename=new.docx&useraddress=${this.USER_ADDRESS}`);
//console.log("FILE+++++++++ ", file);
//          fs.writeFileSync(path, file.getBody());
// console.log("DATA+++++++++ ", data, this.config.document.url);
//           let xhr = new XMLHttpRequest();
//           xhr.open("POST", "create");
//           xhr.setRequestHeader('Content-Type', 'application/json');
//           xhr.send(JSON.stringify(data));
//           xhr.onload = function () {
//             this.innerAlert(xhr.responseText);
//             this.innerAlert(JSON.parse(xhr.responseText).file, true);
//             this.innerAlert('QQQQQQQQQQQQ');
//           }
        }

        //document.title = "";
      },

      onMetaChange : function (event) {  // the meta information of the document is changed via the meta command
          if (event.data.favorite) {
              var favorite = !!event.data.favorite;
              var title = document.title.replace(/^\☆/g, "");
              //document.title = (favorite ? "☆" : "") + title;
              document.title = "";
              docEditor.setFavorite(favorite);  // change the Favorite icon state
          }

          this.innerAlert("onMetaChange: " + JSON.stringify(event.data));
      },

      onRequestEditRights : function () {  // the user is trying to switch the document from the viewing into the editing mode
          location.href = location.href.replace(RegExp("mode=view\&?", "i"), "");
      },

//       onRequestHistory : function (event) {  // the user is trying to show the document version history
// console.log('Ev:: onRequestHistory')
//           var historyObj = [{"changes":null,"key":`${this.USER_ADDRESS}new.docx${DATE_IAT}`,"version":1,"created":"2023-03-31 23:22:13","user":{"id":"uid-1","name":"John Smith"}},{"serverVersion":"7.3.3","changes":[{"created":"2023-04-01 03:22:21","user":{"id":"uid-1","name":"John Smith"}}],"key":"189.15.224.139new.docx11680319350277","version":2,"created":"2023-04-01 03:22:21","user":{"id":"uid-1","name":"John Smith"}}] || null;

//           docEditor.refreshHistory(  // show the document version history
//               {
//                   currentVersion: "2",
//                   history: historyObj
//               });
//       },

//       onRequestHistoryData : function (data) {  // the user is trying to click the specific document version in the document version history
// console.log('Ev:: onRequestHistoryData')
//           var version = data.data;
//           var historyData = [{"fileType":"docx","version":1,"key":"189.15.224.139new.docx1680319333550","url":"${this.URL_SERVER}/history?fileName=new.docx&file=prev.docx&ver=1&useraddress=189.15.224.139","directUrl":null,"token":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmaWxlVHlwZSI6ImRvY3giLCJ2ZXJzaW9uIjoxLCJrZXkiOiIxODkuMTUuMjMwLjI0MnNhbXBsZS5kb2N4MTY4MDMxOTMzMzU1MCIsInVybCI6Imh0dHA6Ly84Ni40OC4yOC4xOTUvZXhhbXBsZS9oaXN0b3J5P2ZpbGVOYW1lPXNhbXBsZS5kb2N4JmZpbGU9cHJldi5kb2N4JnZlcj0xJnVzZXJhZGRyZXNzPTE4OS4xNS4yMzAuMjQyIiwiZGlyZWN0VXJsIjpudWxsLCJpYXQiOjE2ODAzMjg1MjIsImV4cCI6MTY4MDMzMTUyMn0.aZhui2JA2fVXrUbkgee0adHYC_UpF7VYs0dz9oS1f2E"},{"fileType":"docx","version":2,"key":"189.15.224.139new.docx11680319350277","url":"${this.URL_SERVER}/download?fileName=new.docx&useraddress=189.15.224.139","directUrl":null,"previous":{"fileType":"docx","key":"189.15.224.139new.docx1680319333550","url":"${this.URL_SERVER}/history?fileName=new.docx&file=prev.docx&ver=1&useraddress=189.15.224.139","directUrl":null},"changesUrl":"${this.URL_SERVER}/history?fileName=new.docx&file=diff.zip&ver=1&useraddress=189.15.224.139","token":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmaWxlVHlwZSI6ImRvY3giLCJ2ZXJzaW9uIjoyLCJrZXkiOiIxODkuMTUuMjMwLjI0MnNhbXBsZS5kb2N4MTE2ODAzMTkzNTAyNzciLCJ1cmwiOiJodHRwOi8vODYuNDguMjguMTk1L2V4YW1wbGUvZG93bmxvYWQ_ZmlsZU5hbWU9c2FtcGxlLmRvY3gmdXNlcmFkZHJlc3M9MTg5LjE1LjIzMC4yNDIiLCJkaXJlY3RVcmwiOm51bGwsInByZXZpb3VzIjp7ImZpbGVUeXBlIjoiZG9jeCIsImtleSI6IjE4OS4xNS4yMzAuMjQyc2FtcGxlLmRvY3gxNjgwMzE5MzMzNTUwIiwidXJsIjoiaHR0cDovLzg2LjQ4LjI4LjE5NS9leGFtcGxlL2hpc3Rvcnk_ZmlsZU5hbWU9c2FtcGxlLmRvY3gmZmlsZT1wcmV2LmRvY3gmdmVyPTEmdXNlcmFkZHJlc3M9MTg5LjE1LjIzMC4yNDIiLCJkaXJlY3RVcmwiOm51bGx9LCJjaGFuZ2VzVXJsIjoiaHR0cDovLzg2LjQ4LjI4LjE5NS9leGFtcGxlL2hpc3Rvcnk_ZmlsZU5hbWU9c2FtcGxlLmRvY3gmZmlsZT1kaWZmLnppcCZ2ZXI9MSZ1c2VyYWRkcmVzcz0xODkuMTUuMjMwLjI0MiIsImlhdCI6MTY4MDMyODUyMiwiZXhwIjoxNjgwMzMxNTIyfQ.Y0tHBZOimOhbjBmYsYzq96jmm-cTlq4yO2R-WwlHSEg"}] || null;

//           docEditor.setHistoryData(historyData[version-1]);  // send the link to the document for viewing the version history
//       },

      onRequestHistoryClose : function (event){  // the user is trying to go back to the document from viewing the document version history
          document.location.reload();
      },

      onError : function (event) {  // an error or some other specific event occurs
          if (event)
          this.innerAlert(event.data);
      },

      onOutdatedVersion : function (event) {  // the document is opened for editing with the old document.key value
          document.location.reload();
          // location.reload(true);
      },

      replaceActionLink : function(href, linkParam) {
          var link;
          var actionIndex = href.indexOf("&action=");
          if (actionIndex != -1) {
              var endIndex = href.indexOf("&", actionIndex + "&action=".length);
              if (endIndex != -1) {
                  link = href.substring(0, actionIndex) + href.substring(endIndex) + "&action=" + encodeURIComponent(linkParam);
              } else {
                  link = href.substring(0, actionIndex) + "&action=" + encodeURIComponent(linkParam);
              }
          } else {
              link = href + "&action=" + encodeURIComponent(linkParam);
          }
          return link;
      },

      onMakeActionLink : function (event) {  // the user is trying to get link for opening the document which contains a bookmark, scrolling to the bookmark position
          var actionData = event.data;
          var linkParam = JSON.stringify(actionData);
          docEditor.setActionLink(this.replaceActionLink(location.href, linkParam));  // set the link to the document which contains a bookmark
      },

      onRequestInsertImage : function(event) {  // the user is trying to insert an image by clicking the Image from Storage button
          docEditor.insertImage({  // insert an image into the file
              "c": event.data.c,
              "fileType":"png","url":`${this.URL_SERVER}/images/logo.png`,"directUrl":null,"token":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmaWxlVHlwZSI6InBuZyIsInVybCI6Imh0dHA6Ly84Ni40OC4yOC4xOTUvZXhhbXBsZS9pbWFnZXMvbG9nby5wbmciLCJkaXJlY3RVcmwiOm51bGwsImlhdCI6MTY4MDMyODUyMiwiZXhwIjoxNjgwMzMxNTIyfQ.rQOo7K0-0uhXFGUW3ryDEFbfyZK0Z5Akxi1yE10xcvw"
          })
      },

      onRequestCompareFile : function() {  // the user is trying to select document for comparing by clicking the Document from Storage button
          docEditor.setRevisedFile({"fileType":"docx","url":`${this.URL_SERVER}/assets/sample/${this.ARQUIVO}`,"directUrl":null,"token":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmaWxlVHlwZSI6ImRvY3giLCJ1cmwiOiJodHRwOi8vODYuNDguMjguMTk1L2V4YW1wbGUvYXNzZXRzL3NhbXBsZS9zYW1wbGUuZG9jeCIsImRpcmVjdFVybCI6bnVsbCwiaWF0IjoxNjgwMzI4NTIyLCJleHAiOjE2ODAzMzE1MjJ9.PybsSoLfUdIahBvW2DWFoBCv31-7JTPCmCxjFRMahNw"});  // select a document for comparing
      },

      onRequestMailMergeRecipients : function (event) {  // the user is trying to select recipients data by clicking the Mail merge button
          docEditor.setMailMergeRecipients({"fileType":"csv","url":`${this.URL_SERVER}/csv`,"directUrl":null,"token":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmaWxlVHlwZSI6ImNzdiIsInVybCI6Imh0dHA6Ly84Ni40OC4yOC4xOTUvZXhhbXBsZS9jc3YiLCJkaXJlY3RVcmwiOm51bGwsImlhdCI6MTY4MDMyODUyMiwiZXhwIjoxNjgwMzMxNTIyfQ.tr2E1fjka5H70mzzS2-DwQlXH2ITd3EgoC62XxB6-nQ"});  // insert recipient data for mail merge into the file
      },

      onRequestUsers : function () {  // add mentions for not anonymous users
          docEditor.setUsers({  // set a list of users to mention in the comments
              "users": [{"name":"Mark Pottato","email":"pottato@example.com"},{"name":"Hamish Mitchell","email":"mitchell@example.com"}]
          });
      },

      onShowMessage : function (event) {  // add mentions for not anonymous users
        console.log('MESSAGE', event);
      },

      onRequestSendNotify : function(event) {  // the user is mentioned in a comment
          event.data.actionLink = this.replaceActionLink(location.href, JSON.stringify(event.data.actionLink));
          var data = JSON.stringify(event.data);
          this.innerAlert("onRequestSendNotify: " + data);
      },

      onRequestReferenceData : function(event) {  // user refresh external data source
        this.innerAlert("onRequestReferenceData");
        this.innerAlert(event.data);

          event.data.directUrl = !! this.config.document.directUrl;

          let xhr = new XMLHttpRequest();
          xhr.open("POST", "reference");
          xhr.setRequestHeader("Content-Type", "application/json");
          xhr.send(JSON.stringify(event.data));
          xhr.onload = function () {
            this.innerAlert(xhr.responseText);
              docEditor.setReferenceData(JSON.parse(xhr.responseText));
          }
      },

      onRequestSaveAs : function (event) {  //  the user is trying to save file by clicking Save Copy as... button
          var title = event.data.title;
          var url = event.data.url;
          var data = {
              title: title,
              url: url
          }
          let xhr = new XMLHttpRequest();
          xhr.open("POST", "create");
          xhr.setRequestHeader('Content-Type', 'application/json');
          xhr.send(JSON.stringify(data));
          xhr.onload = function () {
            this.innerAlert(xhr.responseText);
            this.innerAlert(JSON.parse(xhr.responseText).file, true);
          }
      },

      onRequestRename : function(event) { //  the user is trying to rename file by clicking Rename... button
        this.innerAlert("onRequestRename: " + JSON.stringify(event.data));

          var newfilename = event.data;
          var data = {
              newfilename: newfilename,
              dockey: config.document.key,
              ext: config.document.fileType
          };
          let xhr = new XMLHttpRequest();
          xhr.open("POST", "rename");
          xhr.setRequestHeader('Content-Type', 'application/json');
          xhr.send(JSON.stringify(data));
          xhr.onload = function () {
            this.innerAlert(xhr.responseText);
          }
      },

      connectEditor : async function () {
        if ((this.config.document.fileType === "docxf" || this.config.document.fileType === "oform")
            && DocsAPI.DocEditor.version().split(".")[0] < 7) {
              this.innerAlert("Please update ONLYOFFICE Docs to version 7.0 to work on fillable forms online.");
            return;
        }

        // Criar um documento ------------------------------------------------
        if (this.ACAO == 'NOVO_MODELO_LIMPO') {
          //console.log('PASSOU EM TENTANDO CRIAR ????????????????? ', this.ACAO)
          await this.cria_documento("https://app-imob.younect.com.br/download/novo_em_branco.docx");
        }
        else if (this.ACAO == 'NOVO_MODELO_UPLOAD') {
          //await this.cria_documento(`http://localhost:3000/files/tmp/${this.ARQUIVO}`);
          console.log('cria_png=========', this.payload.document.url);
          await this.cria_png(this.payload.document.url)
        }

        // Abrir documento ---------------------------------------------------
        this.docEditor    = await new DocsAPI.DocEditor("iframeEditor", this.config);

        this.fixSize();
      },

      // get the editor sizes
      fixSize : function () {
        var wrapEl = document.getElementsByClassName("form");
        if (wrapEl.length) {
            wrapEl[0].style.height = screen.availHeight-64-2 + "px";
            window.scrollTo(0, -1);
            wrapEl[0].style.height = window.innerHeight-64-2 + "px";
        }
        var tollbarEl = document.getElementById("toolbar");
        console.log("tollbarEl", tollbarEl);

      },

    },

    computed: {
      isMobile() {
        return this.$vuetify.breakpoint.name === "xs";
      },
    },

  };
  </script>

  <style lang="scss" scoped>
  .title-page {
    width: 600px !important;
    height: 80px;
    font-family: "Open Sans", sans-serif !important;
    font-size: 17px !important;
  }

  @media(max-width: 599px) {
    .title-page {
      width: 100% !important;
    }

    .text-opacity {
      opacity: 0.7;
    }
  }

  @media(max-width: 375px) {
    .tab-mobile {
      margin-left: -44px;
    }
  }

  .text-white {
    color: #FFFFFF;
    letter-spacing: 0px;
  }

  .btn-icon:hover {
    background: rgba(255, 255, 255, 0.1);
  }

  .btn-icon:hover .icon-action {
    color: #FFF !important;
    opacity: 0.8;
  }

  .nav-link {
    text-decoration: none;
  }

  .container {
    max-width: 100%;
    height: calc(100vh - 110px) !important;
  }

  @media(max-width: 599px) {
    .container {
      max-width: 100% !important;
      height: calc(100vh - 160px) !important;
      margin-top: -4px !important;
    }

    .avatar {
      margin-top: 4px;
    }
  }

  .card {
    height: 100% !important;
    border-radius: 8px;
    background: var(--COR_SECUNDARIA);
    box-shadow: var(--COR_SOMBRA) !important;
  }

  .card-content {
    height: 100% !important;
  }

  .wrapper-top {
    height: 105px !important;
  }

  .header-text {
    font-family: "Open Sans", sans-serif !important;
    color: #505050;
  }
  .extra {
    display: none;
  }
  .logo {
    display: none;
  }
  .header-logo {
    display: none;
  }

  .wrapper-bottom {
    height: calc(100% - 105px) !important;
  }

  .divisor-horizontal {
    // display: none;
    width: 100% !important;
    position: relative;
  }

  .divisor-vertical {
    // display: none;
    height: 100% !important;
    position: relative
  }

  @media(max-width: 599px) {
    .wrapper-bottom {
      width: 100%;
      flex-direction: column !important;
      justify-content: center;
      margin-right: 0px;
      margin-left: 0px;
    }

    .divisor-vertical {
      display: none;
    }
  }

  .col-top {
    width: 100vw;
  }

  .col-esquerda {
    max-width: 220px;
    height: 100%;
    position: relative;
    background-color: var(--COR_BACKGROUND) !important;
    margin-top: 0px;
    padding-top: 2px !important;
  }

  .overflow-y-scroll {
    overflow-y: auto;
  }

  .size-icon {
    font-size: 20px;
  }

  .size-icon-expanded {
    font-size: 15px;
  }

  .btn-expanded {
    min-width: 5px !important;
    max-width: 8px !important;
    height: 80px !important;
    position: absolute;
    top: 50%;
    right: -5px;
    z-index: 1;
  }

  .col-direita {
    height: 100%;
    width: 70%;
  }

  @media(max-width: 599px) {
    .col-direita {
      padding-right: 4px !important;
      padding-left: 0px !important;
      width: 100%;
    }
  }

  .list {
    background-color: transparent;
    min-width: 220px;
    margin-top: -2px;
    color: var(--COR_SUBTITULO);
  }

  .theme--dark.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
    color: #fff !important;
  }

  .bg-active {
    background-color: var(--COR_PRINCIPAL);
    color: white !important;
  }

  .cabecalho {
    background-color: var(--COR_BACKGROUND) !important;
  }

  @media(max-width: 599px) {
    .cabecalho {
      padding-bottom: 10px !important;
    }
  }

  #widget {
    left: 0px;
    top: 0px;
    width: 120px;
    height: 26px;
    position: absolute;
    display: block;
    z-index:2;
    background-color: transparent;
  }
  #widget2 {
    position: absolute;
    display: block;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 25px;
    z-index: 2;
    background-color: transparent;
    margin: 0px 0 0 280px;
  }
   #frame {
            z-index:1;
          }
  </style>
